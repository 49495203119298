import {
    COORDINATES_LOAD_START, COORDINATES_LOAD_DONE, COORDINATES_LOAD_FAIL,
} from '../actionTaypes';

const INITIAL_STATE = {
    loading: true,
    error: undefined,
    coordinates: [],
};
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case COORDINATES_LOAD_START:
            return {...state, loading: true, error: undefined};
        case COORDINATES_LOAD_DONE: {
            return {
                ...state,
                loading: false,
                error: undefined,
                coordinates: [...action.coordinates],
            };
        }
        case COORDINATES_LOAD_FAIL:
            return {...state, loading: false, error: action.payload.error, data: undefined};

        default:
            return state;
    }
}
