import {
    FLOOR_IMAGE_LOAD_DONE, FLOOR_IMAGE_LOAD_FAIL,
    FLOOR_IMAGE_LOAD_START
} from '../actionTaypes';

const INITIAL_STATE = {
    loading: true,
    error: undefined,
    floorImages: [],
};
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case FLOOR_IMAGE_LOAD_START:
            return {...state, loading: true, error: undefined};
        case FLOOR_IMAGE_LOAD_DONE: {
            return {
                ...state,
                loading: false,
                error: undefined,
                floorImages: [...action.floorImages],
            };
        }
        case FLOOR_IMAGE_LOAD_FAIL:
            return {...state, loading: false, error: action.payload.error, floorImages: undefined};

        default:
            return state;
    }
}
