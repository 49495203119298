import {all, put, takeLatest} from '@redux-saga/core/effects';
import {poi} from '../../data/data.json'
import {poi as coordinatesForFirstBuilding} from '../../data/coordinates-1.json'
import {poi as coordinatesForSecondBuilding} from '../../data/coordinates-2.json'
import * as actionTypes from '../actionTaypes';
import {loadCoordinatesDone} from '../actions';


export function* watchCoordinates() {
    yield all([takeLatest(actionTypes.COORDINATES_LOAD_START, loadInitialCoordinates)]);
}

function* loadInitialCoordinates(action) {
    const {activeFloor, imgIndex, activeRoomType, availability, surfaceVal, building, budgetVal} = action.payload;

    const floor = activeFloor.replace('floor-', '')
    let currentBuilding;
    let pois = poi;

    if (+building === 1) {
        currentBuilding = coordinatesForFirstBuilding
    } else {
        currentBuilding = coordinatesForSecondBuilding
    }
    if (floor !== "all") {
        pois = pois.filter(poi => {
            return parseInt(poi.floor) === parseInt(floor)
        })
    }

    if (activeRoomType.length > 0) {
        pois = pois.filter(poi => {
            return parseInt(activeRoomType.indexOf(poi.type)) !== -1
        })
    }
    if (availability.length > 0) {
        pois = pois.filter(poi => availability.includes(poi.visible) && poi
        )
    }
    pois = pois.filter(item => +item.surface >= +surfaceVal.start && +item.surface <= +surfaceVal.end)
    pois = pois.filter(item => +item.price >= +budgetVal.start && +item.price <= +budgetVal.end)
    let pickUpCoordinates;
    let arrayOfPoisCoordinates = []

    pois.map(poi => {
        for (const coordinate in currentBuilding) {
            if (poi.lot === coordinate) {

                pickUpCoordinates = currentBuilding[coordinate].filter(item => {
                    return item.hasOwnProperty(activeFloor)
                })

                if (pickUpCoordinates.length > 0) {
                    pickUpCoordinates = pickUpCoordinates[0][activeFloor].filter(element => element['_img'] === imgIndex);
                    if (pickUpCoordinates.length > 0) {
                        arrayOfPoisCoordinates.push({id: poi.id, coordinates: pickUpCoordinates[0]})
                    }
                }
                break
            }
        }
    })
    yield put(loadCoordinatesDone(arrayOfPoisCoordinates));
}


