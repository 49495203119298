import React, {useEffect, useReducer, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import RotatableBuilding from "./components/RotatableBuilding/RotatableBuilding";
import {loadCoordinates, loadData, loadFloorImages, loadMaxMinPrice, loadMaxMinSurface} from "./store/actions";
import Filter from "./components/Filter/Filter";
import './App.scss'
import {floorImages as allFloorImages} from './data/floorImages.json'
import {AppContext} from "./context";
import {reducer} from "./App.store";
import {logDOM} from "@testing-library/react";


const App = () => {
    const dispatch = useDispatch();
    const {building} = useParams();
    const {roomTypes, floors, maxMinSurface, maxMinPrice, currentPoi} = useSelector(state => state.data);
    const {floorImages} = useSelector(state => state.floor)

    const [appState, setAppState] = useReducer(reducer, {
        activeFloor: 'floor-all',
        activeRoomType: [],
        availability: ["1", "0"],
        surfaceVal: {start: 0, end: 0},
        budgetVal: {start: 0, end: 0},
        imageIndex: 0,
        isMenuOpen: false,
        secondPage: {filterOpen: false, imageOpen: false},
        viewPort: '1',
        appLoading: true
    })
    const {
        surfaceVal,
        budgetVal,
        activeRoomType,
        availability,
        activeFloor,
        imageIndex,
        secondPage,
        appLoading
    } = appState

    useEffect(() => {
            const loadImage = async image => {
                return new Promise((resolve, reject) => {
                    const loadImg = new Image()
                    loadImg.src = image.url
                    loadImg.onload = () =>
                        setTimeout(() => {
                            resolve(image.url)
                        }, 1000)
                    loadImg.onerror = err => reject(err)
                })
            }

            allFloorImages.push(allFloorImages[allFloorImages.length - 1])
            const getAllImages = async () => {
                return Promise.all(allFloorImages.map((item, index) => {
                    const kayName = Object.keys(item)
                    let eachFloor = item[kayName].map((url, id) => {
                        if (allFloorImages.length - 1 === 8 && kayName[0] === 'floor-all') {
                            return {
                                id: id,
                                url: `${process.env.PUBLIC_URL}/floor/Building2/${Object.keys(item)}/${url}`
                            }
                        }
                        return {
                            id: id,
                            url: `${process.env.PUBLIC_URL}/floor/Building1/${Object.keys(item)}/${url}`
                        }

                    })
                    return Promise.all(eachFloor.map(image => loadImage(image)))
                        .then((data) => {
                        })
                        .catch(err => console.log("Failed to load images", err))
                }))
            }
            getAllImages().then(data => {
                setAppState({prop: 'appLoading', value: false})
                localStorage.setItem("firstEnter", "1")
            })

    }, [appLoading])

    useEffect(() => {
        dispatch(loadData(activeFloor, building));
    }, [activeFloor, activeRoomType, availability])

    useEffect(() => {
        dispatch(loadCoordinates(activeFloor, imageIndex, activeRoomType, availability, surfaceVal, building, budgetVal))
    }, [activeFloor, imageIndex, activeRoomType, availability, surfaceVal, budgetVal])

    useEffect(() => {
        dispatch(loadFloorImages(activeFloor))
    }, [activeFloor])

    useEffect(() => {
        if (maxMinSurface) {
            setAppState({prop: 'surfaceVal', value: {start: maxMinSurface.min, end: maxMinSurface.max}})
        }
    }, [maxMinSurface])


    useEffect(() => {
        if (maxMinPrice) {
            setAppState({prop: 'budgetVal', value: {start: maxMinPrice.min, end: maxMinPrice.max}})
        }
    }, [maxMinPrice])
    return (
        <AppContext.Provider value={appState}>
            <Container fluid className="main-wrapper">
                <div className="d-flex flex-row">
                    {appLoading &&
                    <div className={"loading-container"}>
                        <div className="loading"/>
                        <div id="loading-text">loading</div>
                    </div>
                    }
                    <Filter
                        roomTypes={roomTypes}
                        floors={floors}
                        maxMinSurface={maxMinSurface}
                        maxMinPrice={maxMinPrice}
                        setAppState={setAppState}
                    />
                    <div className='building-wrapper p-0'>
                        {floorImages.length > 0 && !secondPage.imageOpen &&
                        <RotatableBuilding
                            activeBuilding={building}
                            floorImages={floorImages}
                            setAppState={setAppState}
                        />}
                        {secondPage.imageOpen &&
                        <div className="poi-page__axonos">
                            <img
                                src={`${process.env.PUBLIC_URL}/axonos/${currentPoi.axono}.png`} alt=""/>
                        </div>}
                    </div>

                </div>

            </Container>
        </AppContext.Provider>
    )
}
export default App
