import {all} from 'redux-saga/effects';
import {watchCurrentData, watchData} from "./data";
import {watchFloorImages} from "./floor";
import {watchCoordinates} from "./coordinates";


export function* watchAll() {
    yield all([
        watchData(),
        watchCoordinates(),
        watchFloorImages(),
        watchCurrentData()
    ]);
}
