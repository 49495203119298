import React, {useContext, useEffect, useState} from "react";
import {Card, Button, ListGroup, ListGroupItem, Spinner} from "react-bootstrap";
import './RotatableBuilding.scss'
import {useSelector} from "react-redux";
import ImageMapper from "react-image-mapper/lib/ImageMapper";
import {ReactComponent as Room} from "../../assets/icons/room.svg";
import {ReactComponent as Surface} from "../../assets/icons/surface1.svg";
import {ReactComponent as Floor} from "../../assets/icons/floor.svg";
import './ProjectPois.scss'
import {AppContext} from "../../context";

const ProjectPois = ({
                         coordinates,
                         url,
                         dimensions,
                         dragging,
                         isLoad,
                         setAppState
                     }) => {


    const [MAP, setMap] = useState({name: "my-map", areas: []})
    const [menuDivWidth, setMenuDivWidth] = useState(0)
    const [isMobile, setIsMobile] = useState(false)
    const [hoveredArea, setHoveredArea] = useState(null)
    const [currentWidth, setCurrentWidth] = useState(0)
    const [currentHeight, setCurrentHeight] = useState(0)
    const {pois} = useSelector(state => state.data);
    const state = useContext(AppContext);

    const {
        isMenuOpen,
        imageIndex
    } = state


    useEffect(() => {
            let createMap = [];
            if (!dragging) {
                let differenceWidth = 0;
                let differenceHeight = 0

                var ratio = 1920 / 1080
                var width = currentHeight * ratio
                var height = currentHeight
                if (width > currentWidth) {
                    width = currentWidth
                    height = currentWidth / ratio
                }
                createMap = coordinates.map(item => {
                    if (item && item.coordinates && item.coordinates['_vertices']) {
                        let coordinatesSplit = item.coordinates['_vertices'].split(';')
                        let coordinatesParam = coordinatesSplit.map(item => {
                            let n = item.split(',')
                            differenceWidth = 1920 / width;
                            differenceHeight = 1080 / height;

                            n[0] = (n[0] / differenceWidth) + ((currentWidth - width) / 2)
                            n[1] = (n[1] / differenceHeight) + ((currentHeight - height) / 2)
                            return n
                        })

                        const poi = pois.filter(poi => poi.id === item.id)

                        return {
                            name: poi[0],
                            shape: "poly",
                            coords: coordinatesParam.flat(),
                            preFillColor: parseInt(poi[0].visible) ? "rgba(7, 153, 6,0.4)" : "rgba(153, 7, 6,0.4)",
                            fillColor: parseInt(poi[0].visible) ? "rgba(7, 153, 6,0.5)" : "rgba(153, 7, 6,0.5)",
                        }
                    }
                })
            }
            setMap({...MAP, areas: createMap})

        }, [coordinates, currentWidth,currentHeight, imageIndex, dragging, dimensions, pois]
    )

    useEffect(() => {
        const windowWidth = window.innerWidth
        if (windowWidth > 768) {
            setCurrentWidth(window.innerWidth)
            setCurrentHeight(window.innerHeight)
        } else {
            setCurrentWidth(document.getElementById('imageMapperDiv').offsetWidth)
            setCurrentHeight(document.getElementById('imageMapperDiv').offsetHeight)
        }
    }, [dimensions]);

    useEffect(() => {
        const windowWidth = window.innerWidth
        if (windowWidth <= 768) {
            return setIsMobile(true)
        }
        return setIsMobile(false)
    }, []);


    useEffect(() => {
        let img = document.querySelector("#imageMapperDiv img")
        img.setAttribute('draggable', false)
        img.setAttribute('ondragstart', "return false;")
    }, [MAP])


    useEffect(() => {
        if (isMenuOpen) {
            setMenuDivWidth(document.getElementById("sidebar").offsetWidth)
        }
    }, [menuDivWidth])

    const load = () => {
        setMap({...MAP})
    }
    const enterArea = (area) => {
        setHoveredArea(area)
    }

    const onClickedInsideImage = (area) => {
        setAppState({prop: 'secondPage', value: {filterOpen: true, imageOpen: false, id: area.name.lot}})
        setAppState({prop: 'isMenuOpen', value: true})
    }

    const moveOnImage = () => {
        setHoveredArea(null)

    }

    const getTipPosition = (area) => {
        if (isMenuOpen) {
            return {top: `${area.center[1]}px`, left: `${area.center[0] + menuDivWidth - 50}px`};
        }
        return {top: `${area.center[1]}px`, left: `${area.center[0] + 200}px`};
    }
    const handleMoreClick = (id) => {
        setAppState({prop: 'secondPage', value: {filterOpen: true, imageOpen: false, id: id}})
        setAppState({prop: 'isMenuOpen', value: true})
    }

    return (

        <div id='imageMapperDiv'>
            {isLoad &&
            <div className='spinner-load'>
                <Spinner animation="border" role="status" variant="success">
                    <span className="visually-hidden"/>
                </Spinner>
            </div>
            }
            <ImageMapper src={url} map={MAP}
                         onLoad={() => load()}
                         onMouseEnter={area => enterArea(area)}
                         onImageMouseMove={evt => moveOnImage(evt)}
                         onClick={(area, index) => onClickedInsideImage(area, index)}
                         width={currentWidth}
                         height={currentHeight}
            />
            {
                !isMobile && hoveredArea &&
                <div className="apartmentPopup" style={{...getTipPosition(hoveredArea)}}>
                    {hoveredArea && hoveredArea.name &&
                    <Card style={{width: '18rem'}}>
                        <div className='card-img-wrapper'>
                            <Card.Img className='apartmentAxonosImg' variant="top"
                                      src={`${process.env.PUBLIC_URL}/axonos/${hoveredArea.name.axono}.png`}/>
                        </div>

                        <Card.Body>
                            <Card.Title>Number: {hoveredArea.name.lot}</Card.Title>
                            <Card.Text>
                                <p><Room/>{hoveredArea.name.type === "1" ? "Studio": `${hoveredArea.name.type} bedroom apartment`}</p>
                                <p><Surface/> {hoveredArea.name.surface} m²</p>
                                <p><Floor/> {hoveredArea.name.floor} floor</p>
                                {parseInt(hoveredArea.name.visible) ?
                                    <p className='status' style={{color: "#4d89ad"}}>Available</p> :
                                    <p className='status' style={{"color": "red"}}>Reserved</p>
                                }
                                {parseInt(hoveredArea.name.visible) ?
                                    <Button variant="link" className='more'
                                            onClick={() => handleMoreClick(hoveredArea.name.lot)}>
                                        See more details
                                    </Button>
                                    : null
                                }

                            </Card.Text>
                        </Card.Body>
                    </Card>
                    }
                </div>

            }
        </div>
    )
};
export default ProjectPois