import React, {useState, useEffect, useRef, useCallback, useContext} from "react";
import PropTypes from "prop-types";
import config from "../../manifest.json";

import {ListGroup, Collapse, Card, Form, Image} from "react-bootstrap";
import {List, ChevronCompactDown} from "react-bootstrap-icons";
import {
    Provider,
    RangeSlider,
    lightTheme,
    RadioGroup,
    Radio,
    CheckboxGroup,
    Checkbox,
    Content,
    View,
    Item,
    ActionGroup
} from "@adobe/react-spectrum";

import "./Filter.scss";
import "rc-tooltip/assets/bootstrap.css";
import "../../../node_modules/rc-slider/assets/index.css";
import {Link} from "react-router-dom";
import Poi from "../../page/Poi";
import {AppContext} from "../../context";

const Filter = ({roomTypes, floors, maxMinSurface, maxMinPrice, setAppState}) => {

    const state = useContext(AppContext);
    const {
        surfaceVal,
        budgetVal,
        activeRoomType,
        availability,
        activeFloor,
        isMenuOpen,
        secondPage,
        viewPort,
    } = state

    const filterContentRef = useRef();

    const [floorFltOpen, setFloorFltOpen] = useState(true);
    const [toplgFltOpen, setToplgFltOpen] = useState(true);
    const [avlbFltOpen, setAvlbFltOpen] = useState(true);


    return (
        <nav id="sidebar" className={(isMenuOpen ? "active " : "") + "col-md-2"}>
            <Link to="/" className={'sidebar-header__logo'}>
                <Image className='sidebar-header__logo--img'
                       src={`${process.env.PUBLIC_URL}/logo.png`}
                />
            </Link>
            <div
                className="sidebar-header__toggle"
                onClick={() => {
                    setAppState({prop: 'isMenuOpen', value: !isMenuOpen})
                }}
            >
                <List width="2em" height="2em" color="#4d89ad"/>
            </div>
            <div className="sidebar-header">
                <div className="sidebar-header__content">
                    <p className="sidebar-header__title">
                        Apartment block
                    </p>
                </div>
            </div>
            {secondPage.filterOpen && secondPage.id ?
                <Poi id={secondPage.id} setAppState={setAppState}/>
                :
                <div className="sidebar-content" ref={filterContentRef}>
                    <Provider theme={lightTheme} colorScheme="light">
                        <ListGroup variant="flush" className="sidebar-content__list">

                            <ListGroup.Item className="sidebar-content-item">
                                <ActionGroup
                                    selectionMode="single"
                                    selectedKeys={new Set(viewPort)}
                                    onSelectionChange={
                                        (list) => {
                                            setAppState({prop: 'viewPort', value: Array.from(list)[0]})
                                            setAppState({prop: 'activeFloor', value: 'floor-all'})
                                        }
                                    }
                                >
                                    <Item key="1">Aerial</Item>
                                    <Item key="2">Pedestrian</Item>
                                </ActionGroup>
                            </ListGroup.Item>
                            {activeFloor !== 'floor-all' &&
                            <>
                                <ListGroup.Item className="sidebar-content-item">
                                    <View
                                        width={"100%"}
                                        borderWidth="thin"
                                        borderColor="dark"
                                        borderRadius="medium"
                                        padding="size-100"
                                    >
                                        <RangeSlider
                                            width={"100%"}
                                            label="surface m²"
                                            value={surfaceVal}
                                            onChange={(val) => setAppState({
                                                prop: 'surfaceVal', value: {
                                                    'start': val.start,
                                                    'end': val.end
                                                }
                                            })}
                                            minValue={maxMinSurface.min}
                                            maxValue={maxMinSurface.max}
                                            getValueLabel={(euro) => `${euro.start} m² - ${euro.end} m²`}
                                        />
                                    </View>
                                </ListGroup.Item>
                                <ListGroup.Item className="sidebar-content-item">
                                    <View
                                        width={"100%"}
                                        borderWidth="thin"
                                        borderColor="dark"
                                        borderRadius="medium"
                                        padding="size-100"
                                    >
                                        <RangeSlider
                                            width={"100%"}
                                            label="price"
                                            value={{'start': budgetVal.start / 1000, 'end': budgetVal.end / 1000}}
                                            onChange={(val) => setAppState({
                                                prop: 'budgetVal', value: {
                                                    'start': val.start * 1000,
                                                    'end': val.end * 1000
                                                }
                                            })}
                                            minValue={maxMinPrice.min / 1000}
                                            maxValue={maxMinPrice.max / 1000}
                                            getValueLabel={(euro) => `${euro.start}k £  - ${euro.end}k £`}
                                            formatOptions={{style: 'currency', currency: 'GBP'}}
                                        />
                                    </View>
                                </ListGroup.Item>
                            </>
                            }
                            {config.floor && (
                                <ListGroup.Item className="sidebar-content-item">
                                    <Card>
                                        <Card.Header
                                            className={"sidebar-content-item__header"}
                                            onClick={() => setFloorFltOpen(!floorFltOpen)}
                                        >
                                            <label>
                                                <Content>Floors</Content>
                                            </label>
                                            <ChevronCompactDown
                                                className={
                                                    "sidebar-content-item__header--icon" +
                                                    (floorFltOpen ? " active" : "")
                                                }
                                            />
                                        </Card.Header>

                                        <Collapse in={floorFltOpen}>
                                            <Form className={"sidebar-content-item__body"}>
                                                <View
                                                    borderWidth="thin"
                                                    borderColor="dark"
                                                    borderRadius="medium"
                                                    padding="size-250"
                                                >
                                                    <RadioGroup aria-label="floor" label="" value={activeFloor}
                                                                onChange={(floor) => {
                                                                    setAppState({prop: 'viewPort', value: []})
                                                                    setAppState({prop: 'activeFloor', value: floor})
                                                                }
                                                                }>
                                                        {floors &&
                                                        floors.map((floor) => {
                                                            let label = ""
                                                            switch (floor) {
                                                                case 0:
                                                                    label = "Ground floor"
                                                                    break
                                                                case 1:
                                                                    label = "1st floor"
                                                                    break
                                                                case 2:
                                                                    label = "2nd floor"
                                                                    break
                                                                case 3:
                                                                    label = "3rd floor"
                                                                    break
                                                                case 4:
                                                                case 5:
                                                                    label = `${floor}th floor`
                                                                    break
                                                                default:
                                                                    label = floor
                                                            }
                                                            return <Radio
                                                                UNSAFE_style={{
                                                                    borderColor: "blue",
                                                                }}
                                                                key={`floor-${floor}`}
                                                                value={`floor-${floor}`}
                                                            >
                                                                {label}
                                                            </Radio>
                                                        })}
                                                    </RadioGroup>
                                                </View>
                                            </Form>
                                        </Collapse>
                                    </Card>
                                </ListGroup.Item>
                            )}
                            {config.type && activeFloor !== 'floor-all' && (
                                <ListGroup.Item className="sidebar-content-item">
                                    <Card>
                                        <Card.Header
                                            className={"sidebar-content-item__header"}
                                            onClick={() => setToplgFltOpen(!toplgFltOpen)}
                                        >
                                            <label>Apartment type</label>
                                            <ChevronCompactDown
                                                className={
                                                    "sidebar-content-item__header--icon" +
                                                    (toplgFltOpen ? " active" : "")
                                                }
                                            />
                                        </Card.Header>

                                        <Collapse in={toplgFltOpen}>
                                            <Form className={"sidebar-content-item__body"}>
                                                <View
                                                    borderWidth="thin"
                                                    borderColor="dark"
                                                    borderRadius="medium"
                                                    padding="size-250"
                                                >
                                                    <CheckboxGroup
                                                        aria-label='theme'
                                                        label=""
                                                        value={activeRoomType}
                                                        onChange={(val) => {
                                                            setAppState({
                                                                prop: 'activeRoomType', value: val
                                                            })
                                                        }}
                                                    >
                                                        {
                                                            roomTypes && roomTypes.map((roomType) => {
                                                                let label = ""
                                                                switch (roomType){
                                                                    case "1":
                                                                        label = "Studio"
                                                                        break
                                                                    case "2":
                                                                    case "3":
                                                                    case "4":
                                                                        label = `${roomType} bedroom`
                                                                        break
                                                                    default:
                                                                        label = roomType
                                                                }
                                                                return (
                                                                    <Checkbox
                                                                        key={`room-${roomType}`}
                                                                        value={roomType}
                                                                    >
                                                                        {label}
                                                                    </Checkbox>
                                                                )
                                                            })
                                                        }
                                                    </CheckboxGroup>
                                                </View>
                                            </Form>
                                        </Collapse>
                                    </Card>
                                </ListGroup.Item>
                            )}
                            {activeFloor !== 'floor-all' &&

                            <ListGroup.Item className="sidebar-content-item">
                                <Card>
                                    <Card.Header
                                        className={"sidebar-content-item__header"}
                                        onClick={() => setAvlbFltOpen(!avlbFltOpen)}
                                    >
                                        <label>Availability</label>
                                        <ChevronCompactDown
                                            className={
                                                "sidebar-content-item__header--icon" +
                                                (avlbFltOpen ? " active" : "")
                                            }
                                        />
                                    </Card.Header>

                                    <Collapse in={avlbFltOpen}>
                                        <Form className={"sidebar-content-item__body"}>
                                            <View
                                                borderWidth="thin"
                                                borderColor="dark"
                                                borderRadius="medium"
                                                padding="size-250"
                                            >
                                                <CheckboxGroup
                                                    aria-label="22"
                                                    label=""
                                                    value={availability}
                                                    onChange={(val) => {
                                                        {
                                                            setAppState({
                                                                prop: 'availability', value: val
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {
                                                        ["1", "0"].map((availability) => (
                                                            <Checkbox
                                                                key={`${availability}`}
                                                                value={availability}
                                                            >
                                                                {parseInt(availability) ? "Available" : "Reserved"}
                                                            </Checkbox>
                                                        ))
                                                    }
                                                </CheckboxGroup>
                                            </View>
                                        </Form>
                                    </Collapse>
                                </Card>
                            </ListGroup.Item>
                            }
                        </ListGroup>
                    </Provider>
                </div>

            }
        </nav>
    );
};
Filter.propTypes =
    {
        roomTypes: PropTypes.array,
    };

export default Filter;
