import {combineReducers} from 'redux';
import data from "./data";
import coordinates from "./coordinates";
import floor from "./floor";

const rootReducer = combineReducers({
    data,
    coordinates,
    floor,
})
export default rootReducer;