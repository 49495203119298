import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loadCurrentData, loadData} from "../store/actions";

import {
    lightTheme,
    Provider,
} from "@adobe/react-spectrum";

import './Poi.scss'
import {Button, Card, Col, Container, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {AppContext} from "../context";

const Poi = ({id, setAppState}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadCurrentData(id))
    }, [id])

    const {currentPoi} = useSelector(state => state.data)

    const handleImgOpen = (secondPage) => {
        setAppState({prop: 'secondPage', value: {...secondPage, imageOpen: true}})
    }
    const handlePoiClose = (secondPage) => {
        setAppState({prop: 'secondPage', value: {...secondPage, filterOpen: false, imageOpen: false}})
    }
    return (
        <AppContext.Consumer>
            {({secondPage}) => (
                <Provider theme={lightTheme} colorScheme="light">
                    <div className="poi-page">

                        <Card>
                            <Card.Header>
                                <button type="button" className="close" aria-label="Close" onClick={handlePoiClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <Card.Img style={{cursor: "pointer"}} onClick={() => handleImgOpen(secondPage)}
                                          variant="top"
                                          src={`${process.env.PUBLIC_URL}/axonos/${currentPoi.axono}.png`}/>
                                <Button variant='link' className='visiter'
                                        onClick={() => handleImgOpen(secondPage)}>Open 3D plan</Button>
                            </Card.Header>

                            <Card.Body>
                                <Card.Text>
                                    <Container>
                                        <Row className='mb-4'>
                                            <Col>
                                                <p className='font-weight-bold'>Number</p>
                                                <p>{currentPoi.lot}</p>
                                            </Col>
                                            <Col>
                                                <p className='font-weight-bold'>Price</p>
                                                <p>{currentPoi.price} £</p>
                                            </Col>
                                        </Row>
                                        <Row className='mb-4'>
                                            <Col>
                                                <p className='font-weight-bold'>Area</p>
                                                <p>{currentPoi.surface} m<sup>2</sup></p>
                                            </Col>
                                            <Col>
                                                <p className='font-weight-bold'>Bedrooms</p>
                                                <p>{currentPoi.type === "1" ? "Studio" : `${currentPoi.type} bed`}</p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <p className='font-weight-bold'>Floor</p>
                                                <p>{currentPoi.floor}</p>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Provider>
            )}
        </AppContext.Consumer>
    )
}
export default Poi
