import React, {useEffect, useState, useRef, useContext} from "react";
import {useSelector} from "react-redux";
import ProjectPois from "./ProjectPois";
import {Button} from "react-bootstrap";
import ArrowKeysReact from 'arrow-keys-react';
import {ArrowRight, ArrowLeft, Plus, Dash, X} from 'react-bootstrap-icons';
import {ReactComponent as Compass} from "../../assets/icons/compass.svg";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import ReactTooltip from "react-tooltip";
import {AppContext} from "../../context";

const pixelsPerDegree = 2;
let xDown = null;
const RotatableBuilding = ({floorImages, activeBuilding, setAppState}) => {


    const {coordinates} = useSelector(state => state.coordinates)
    const state = useContext(AppContext);
    let compassOffset = 0

    const imgRef = useRef();
    const contentRef = useRef();
    const [dragging, setDragging] = useState(false);
    const [dragStartIndex, setDragStartIndex] = useState(0);
    const [dragStart, setDragStart] = useState(1);
    const [dimensions, setDimensions] = useState();
    const [originalWidth, setOriginalWidth] = useState();
    const [originalHeight, setOriginalHeight] = useState();
    const [currentWidth, setCurrentWidth] = useState();
    const [currentHeight, setCurrentHeight] = useState();
    const [isLoad, setIsLoad] = useState(false)

    const {
        activeFloor,
        imageIndex,
        isMenuOpen,
        viewPort,
    } = state


    useEffect(() => {
        contentRef.current.focus()
        setDragging(dragging);
    }, [imageIndex, isMenuOpen, activeFloor, viewPort]);


    useEffect(() => {
        if (imgRef.current) {
            setOriginalWidth(imgRef.current.naturalWidth);
            setOriginalHeight(imgRef.current.naturalHeight);
            setCurrentWidth(imgRef.current.clientWidth);
            setCurrentHeight(imgRef.current.clientHeight);
        }

    }, [dimensions, imageIndex]);

    useEffect(() => {
        const innerArrow = document.getElementById("compass");

        if (innerArrow) {
            innerArrow.style["transform"] = `rotate(${-(imageIndex + compassOffset) * 6}deg)`;
            innerArrow.style["-ms-transform"] = `rotate(${-(imageIndex + compassOffset) * 6}deg)`;
            innerArrow.style["-webkit-transform"] = `rotate(${-(imageIndex + compassOffset) * 6}deg)`;
            innerArrow.style["-moz-transform"] = `rotate(${-(imageIndex + compassOffset) * 6}deg)`;
            innerArrow.style["-o-transform"] = `rotate(${-(imageIndex + compassOffset) * 6}deg)`;
        }
    }, [imageIndex])

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp, false);


        document.addEventListener("touchmove", handleTouchMove, false);
        document.addEventListener("touchstart", handleTouchStart);
        document.addEventListener("touchend", handleTouchEnd);

        window.addEventListener("resize", handleResize, false);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp, false);

            window.removeEventListener("resize", handleResize, false);

            document.removeEventListener("touchmove", handleTouchMove, false);
            document.removeEventListener("touchstart", handleTouchStart);
            document.removeEventListener("touchend", handleTouchEnd);

        }
    }, [dragging])

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        })
    };

    const handleMouseDown = event => {
        if(window.innerWidth>=768){
            event.persist();
            setDragging(true);
            setDragStartIndex(imageIndex)
            setDragStart(event.screenX)
        }
    };
    const handleTouchStart = evt => {
        evt.preventDefault()
        setDragging(true);
        xDown = evt.touches[0].clientX;
    };

    const handleMouseMove = event => {
        if (dragging) {
            setIsLoad(true)
            const numImages = floorImages.length;
            const pixelsPerImage = pixelsPerDegree * (360 / numImages);
            let dx = (event.screenX - dragStart) / pixelsPerImage;
            updateImageIndex(dx);
        }

    };

    const handleTouchMove = evt => {
        setIsLoad(true)
        const xUp = evt.touches[0].clientX;
        const dx = xDown - xUp;
        updateImageIndex(dx);
    };

    const handleMouseUp = () => {
        setTimeout(() => {
            setIsLoad(false)
        }, 200)
        setDragging(false)
    };
    const handleTouchEnd = () => {
        setTimeout(() => {
            setIsLoad(false)
        }, 200)
        setDragging(false)
    };
    const updateImageIndex = dx => {
        const numImages = floorImages.length;
        let index = Math.floor(dx) % numImages;
        if (index < 0) {
            index = numImages + index - 1;
        }
        index = (index + dragStartIndex) % numImages;
        if (index !== imageIndex) {
            setAppState({prop: 'imageIndex', value: index})
        }
    };

    const handleArrowsClick = (type) => {
        if (type === 'left') {
            if (imageIndex === floorImages.length - 1)
                return setAppState({prop: 'imageIndex', value: 0})
            setAppState({prop: 'imageIndex', value: imageIndex + 1})
        } else {
            let currentIMageIndex = imageIndex - 1
            if (currentIMageIndex < 0)
                return setAppState({prop: 'imageIndex', value: floorImages.length - Math.abs(currentIMageIndex)})
            setAppState({prop: 'imageIndex', value: currentIMageIndex})
        }
    }

    ArrowKeysReact.config({
        left: () => {
            handleArrowsClick('right')
        },
        right: () => {
            handleArrowsClick('left')
        }
    })


    const renderImage = () => {
            return (
                <div ref={contentRef} {...ArrowKeysReact.events} tabIndex="1">
                    <Compass className="compass" id="compass"/>
                    <TransformComponent>
                        {activeFloor === 'floor-all' && coordinates ?
                            <>
                                <div style={{height: '100vh', width: '100vw'}}>
                                    <img className='building'
                                         draggable={false}
                                         ref={imgRef}
                                         alt=''
                                         src={`${process.env.PUBLIC_URL}/floor/Building${viewPort ? viewPort : activeBuilding}/${activeFloor}/${floorImages[imageIndex]}`}/>
                                </div>
                            </> :
                            <div style={{height: '100vh', width: '100vw'}}>
                                <ProjectPois
                                    coordinates={coordinates.filter(item => item.coordinates['_vertices'])}
                                    url={`${process.env.PUBLIC_URL}/floor/Building${activeBuilding}/${activeFloor}/${floorImages[imageIndex]}`}
                                    currentHeight={currentHeight}
                                    currentWidth={currentWidth}
                                    dimensions={dimensions}
                                    dragging={dragging}
                                    setAppState={setAppState}
                                    isLoad={isLoad}
                                />
                            </div>
                        }
                    </TransformComponent>
                </div>

            )
        }
    ;

    return (


        <TransformWrapper
            panning={{disabled: true}}
            wheel={{disabled: false, step: 0.03}}
            doubleClick={{disabled: true}}
        >
            {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                <div className="rotatable-image" onMouseDown={handleMouseDown}>
                    {renderImage()}
                    <div className='rotatable-buttons'>
                        <div className="tools">
                            <Button variant='link' data-tip data-for="dash"
                                    onClick={() => zoomOut()}><Dash/></Button>
                            <Button variant='link' data-tip data-for="plus"
                                    onClick={() => zoomIn()}><Plus/></Button>
                            <Button variant='link' onClick={() => handleArrowsClick('left')}
                                    data-tip data-for="left"
                            >
                                <ArrowLeft/></Button>
                            <Button variant='link' onClick={() => handleArrowsClick('right')}
                                    data-tip data-for="right">
                                <ArrowRight/>
                            </Button>
                            <Button variant='link' data-tip data-for="reset"
                                    onClick={() => {
                                        setAppState({prop: 'imageIndex', value: 0})
                                        resetTransform()
                                    }}><X/></Button>

                            <ReactTooltip className='tolls-tooltip' id="left" place="top" type="info"
                                          effect="solid">Turn left</ReactTooltip>
                            <ReactTooltip className='tolls-tooltip' id="right" place="top" type="info"
                                          effect="solid">Turn right</ReactTooltip>
                            <ReactTooltip className='tolls-tooltip' id="plus" place="top" type="info"
                                          effect="solid">Zoom</ReactTooltip>
                            <ReactTooltip className='tolls-tooltip' id="dash" place="top" type="info"
                                          effect="solid">Zoom out</ReactTooltip>
                            <ReactTooltip className='tolls-tooltip' id="reset" place="top" type="info"
                                          effect="solid">Reset</ReactTooltip>

                        </div>
                    </div>
                </div>
            )}
        </TransformWrapper>
    )
}
export default RotatableBuilding;
