export const reducer = (state, update) => {
    switch (update.prop) {
        case update.prop:
            return {
                ...state,
                [update.prop]: update.value
            };
        default:
            throw new Error();
    }

}