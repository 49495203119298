import {all, put, takeLatest} from '@redux-saga/core/effects';
import {floorImages} from '../../data/floorImages.json'
import * as actionTypes from '../actionTaypes';
import {loadFloorImagesDone} from '../actions';


export function* watchFloorImages() {
    yield all([takeLatest(actionTypes.FLOOR_IMAGE_LOAD_START, loadInitialFloorImages)]);
}

function* loadInitialFloorImages(data) {
    const chosenFolder = floorImages.filter(folder => folder.hasOwnProperty(data.chosenFloorImagesFoldrName))
    const images = Object.values(chosenFolder[0])[0].sort((a, b) => {
        if (a.length > b.length) {
            return 1
        } else if (a.length < b.length) {
            return -1
        } else {
            return a > b;
        }
    });
    yield put(loadFloorImagesDone(images));
}

