import {all, put, takeLatest} from '@redux-saga/core/effects';
import {poi} from '../../data/data.json'
import * as actionTypes from '../actionTaypes';
import {loadCurrentDataDone, loadDataDone} from '../actions';


export function* watchData() {
    yield all([takeLatest(actionTypes.DATA_LOAD_START, loadInitialData)]);
}


function* loadInitialData(action) {

    let pois = poi;
    let roomType = poi.map(item => item.type);
    roomType = [...new Set(roomType)].sort()

    const maxFloorNumber = poi.reduce((prev, next) => (prev > next.floor) ? prev : next.floor, 0);
    let floors = []
    for (let i = 0; i <= parseInt(maxFloorNumber); i++) {
        floors.push(i)
    }
    const {activeFloor, activeRoomType, availability, surfaceVal, budgetVal} = action;
    if (activeFloor !== 'floor-all') {
        const floorId = activeFloor.replace('floor-', '')
        pois = poi.filter(item => {
            return parseInt(item.floor) === parseInt(floorId)
        })
    }

    // if (activeRoomType.length > 0) {
    //     pois = pois.filter(poi => {
    //         return parseInt(activeRoomType.indexOf(poi.type)) !== -1
    //     })
    // }

    // if (availability.length > 0) {
    //     pois = pois.filter(poi => availability.includes(poi.visible) && poi
    //     )
    // }

    // pois = pois.filter(item => +item.surface >= +surfaceVal.start && +item.surface <= +surfaceVal.end)
    // pois = pois.filter(item => +item.price >= +budgetVal.start && +item.price <= +budgetVal.end)

    const minMaxPrice = poi.sort((prev, current) => {
        return prev.price - current.price
    })
    const minPrice = minMaxPrice[0].price,
        maxPrice = minMaxPrice[minMaxPrice.length - 1].price


    const minMaxSurface = poi.sort((prev, current) => {
        return prev.surface - current.surface
    })
    const minSurface = minMaxSurface[0].surface,
        maxSurface = minMaxSurface[minMaxSurface.length - 1].surface


    yield put(loadDataDone({pois, roomType, floors, minSurface, maxSurface, minPrice, maxPrice}));
}

export function* watchCurrentData() {
    yield all([takeLatest(actionTypes.CURRENT_DATA_LOAD_START, loadCurrentData)]);
}


function* loadCurrentData(action) {
    const currentPoi = poi.filter(item => +item.lot === +action.id)[0]
    yield put(loadCurrentDataDone({currentPoi}));
}


