import * as actionTypes from '../actionTaypes';

export const loadCoordinates = (activeFloor, imgIndex, activeRoomType, availability, surfaceVal, building, budgetVal) => {
    return {
        type: actionTypes.COORDINATES_LOAD_START,
        payload: {activeFloor, imgIndex, activeRoomType, availability, surfaceVal, building, budgetVal}
    };
};

export const loadCoordinatesDone = coordinates => {
    return {
        type: actionTypes.COORDINATES_LOAD_DONE,
        coordinates,
    };
};


export const loadCoordinatesFail = coordinates => {
    return {
        type: actionTypes.COORDINATES_LOAD_FAIL,
        coordinates,
    };
};
export const loadCoordinatesByRoomType = id => {
    return {
        type: actionTypes.COORDINATES_LOAD_FAIL,
        payload: id,
    };

}
// export const loadCoordinatesByRoomTypeDone = (chooseCoordinatesByFloor, imgIndex) => {
//     return {
//         type: actionTypes.COORDINATES_LOAD_START,
//         payload: {chooseCoordinatesByFloor, imgIndex},
//     };
// };
