import * as actionTypes from '../actionTaypes';

export const loadFloorImages = (chosenFloorImagesFoldrName) => {
    return {
        type: actionTypes.FLOOR_IMAGE_LOAD_START,
        chosenFloorImagesFoldrName,
    };
};

export const loadFloorImagesDone = floorImages => {
    return {
        type: actionTypes.FLOOR_IMAGE_LOAD_DONE,
        floorImages,
    };
};

export const loadFloorImagesFail = data => {
    return {
        type: actionTypes.FLOOR_IMAGE_LOAD_FAIL,
        data,
    };
};