import * as actionTypes from '../actionTaypes/data';

export const loadData = (activeFloor, activeBuilding) => {
    return {
        type: actionTypes.DATA_LOAD_START,
        activeFloor,
        activeBuilding
    };
};

export const loadDataDone = data => {
    return {
        type: actionTypes.DATA_LOAD_DONE,
        data,
    };
};

export const loadDataFail = data => {
    return {
        type: actionTypes.DATA_LOAD_FAIL,
        data,
    };
};

export const loadCurrentData = (id) => {
    return {
        type: actionTypes.CURRENT_DATA_LOAD_START,
        id
    };
};

export const loadCurrentDataDone = data => {
    return {
        type: actionTypes.CURRENT_DATA_LOAD_DONE,
        data,
    };
};

export const loadCurrentDataFail = data => {
    return {
        type: actionTypes.CURRENT_DATA_LOAD_FAIL,
        data,
    };
};
