import {
    DATA_LOAD_START,
    DATA_LOAD_DONE,
    DATA_LOAD_FAIL,
    CURRENT_DATA_LOAD_START,
    CURRENT_DATA_LOAD_DONE, CURRENT_DATA_LOAD_FAIL,
} from '../actionTaypes';


const INITIAL_STATE = {
    loading: true,
    error: undefined,
    pois: [],
    roomTypes: [],
    maxMinSurface: {},
    currentPoi: []
};
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case DATA_LOAD_START:
            return {...state, loading: true, error: undefined};
        case DATA_LOAD_DONE: {
            return {
                ...state,
                loading: false,
                error: undefined,
                pois: action.data.pois,
                roomTypes: action.data.roomType,
                floors: action.data.floors,
                maxMinPrice: {max: Math.ceil(action.data.maxPrice), min: Math.floor(action.data.minPrice)},
                maxMinSurface: {max: Math.ceil(action.data.maxSurface), min: Math.floor(action.data.minSurface)},
            };
        }
        case DATA_LOAD_FAIL:
            return {...state, loading: false, error: action.payload.error, data: undefined};

        case CURRENT_DATA_LOAD_START:
            return {...state, loading: true, error: undefined};
        case CURRENT_DATA_LOAD_DONE: {
            return {
                ...state,
                loading: false,
                currentPoi: action.data.currentPoi
            };
        }
        case CURRENT_DATA_LOAD_FAIL:
            return {...state, loading: false, error: action.payload.error, data: undefined};

        default:
            return state;
    }
}
